@import '../../../../node_modules/ts-components/src/styles/variables.less';

.accurityListOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: @app-background;
  box-shadow: 0 0 0 5px @app-background;
}
