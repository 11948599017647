@import '../../../node_modules/ts-components/src/styles/variables.less';

@orange-primary-color: #e35205;;

.loadingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  background-color: @app-background;
}

.loadingPage__logo {
  width: 300px;
}

.loadingPage__progressIndicator {
  width: 300px;
  margin-top: 20px;

  .MuiLinearProgress-bar {
    background-color: @orange-primary-color;
  }
}

.loadingPage__message {
  margin-top: 20px;
}

.loadingPage__goBackLink {
  margin-top: 20px;
}
