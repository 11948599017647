.emailNotificationsToggle {
  width: 24px;
  height: 24px;
  margin-top: 12px;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 9;
  right: 56px;

  button {
    padding: 0;
  }

  .icon .cls-1 {
    fill: rgba(0, 0, 0, 0.54);
  }
}

.accurityDetail__container .detailTopBar {
  position: absolute;
  display: flex;
  align-items: center;
  top: 10px;
  right: 10px;
  z-index: 9;
}
