.commentsDrawerWrapper {
  z-index: 10;
  width: 0;
  height: 100%;
}

.commentsDrawer {
  width: 440px;
  height: calc(100% - 40px);
  margin-top: 20px;
  margin-left: -9px;
}
