.technicalDataMapping__joinInlineGroup {
  &.revisionColoring::before {
    left: -10px;
    right: -26px;
  }
  &.revisionColoring::after {
    left: -10px;
  }
}




