.versionBrowserField__versionBrowserButton {
  margin: 16px 0;
}

.versionBrowserComparison {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 36px;

  .versionBrowserField-old {
    grid-column: 1;
  }

  .versionBrowserField-new {
    grid-column: 2;
  }

  // hide object type icon next to object name
  .accurityDetail__headerIcon {
    display: none;
  }

  .SingleLineField label {
    cursor: default !important;
  }
}

.versionBrowserRevisionSelector {
}
