
.productTermsScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
}

.productTermsScreen__logo {
  width: 300px;
}

.productTermsScreen .switchField {
  margin: 10px 0;
}

.productTermsScreen__buttonsContainer {
  display: flex;
  justify-content: space-between;
}