@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Regular Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Regular Italic Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro UltraLight Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 200;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro UltraLight Italic Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Light Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Light Italic Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Medium Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 500;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Medium Italic Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Semi Bold Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Semi Bold Italic Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Bold Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Bold Italic Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Black Az.otf') format('opentype');
}
@font-face {
  font-family: 'Sofia Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Sofia Pro'), url('../node_modules/ts-components/src/styles/fonts/~../node_modules/ts-components/src/styles/fonts/SofiaPro/Sofia Pro Black Italic Az.otf') format('opentype');
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Remove default margin */
body {
  margin: 0;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
body {
  font-family: 'Sofia Pro';
  font-size: 14px;
  font-weight: 300;
  background-color: #E7E9EC;
  color: #061220;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #CCCCCC;
}
.icon {
  display: block;
  width: 28px;
  height: 28px;
  stroke-width: 0;
}
.icon .cls-1 {
  fill: #061220;
}
.icon.disabled .cls-1 {
  fill: #B6BDC6 !important;
  stroke: #B6BDC6 !important;
}
.icon.inverted .cls-1 {
  fill: #FDFDFD !important;
  stroke: #FDFDFD !important;
}
.icon.default .cls-1 {
  fill: #061220;
  stroke: #061220;
}
.icon.light .cls-1 {
  fill: #8691A0;
  stroke: #8691A0;
}
.icon.error .cls-1 {
  fill: #D5282B;
  stroke: #D5282B;
}
.icon.failed .cls-1 {
  fill: #D5282B;
  stroke: #D5282B;
}
.icon.warning .cls-1 {
  fill: #F7A600;
  stroke: #F7A600;
}
.icon.passed .cls-1 {
  fill: #689f38;
  stroke: #689f38;
}
.icon.primary .cls-1 {
  fill: #6D8CE2;
  stroke: #6D8CE2;
}
.Quality .icon.primary .cls-1 {
  fill: #81bb26;
}
.Reference .icon.primary .cls-1 {
  fill: #ffb500;
}
.Accurity .icon.primary .cls-1 {
  fill: #6D8CE2;
}
.Quality .icon.primary .cls-1 {
  stroke: #81bb26;
}
.Reference .icon.primary .cls-1 {
  stroke: #ffb500;
}
.Accurity .icon.primary .cls-1 {
  stroke: #6D8CE2;
}
.icon.secondary .cls-1 {
  fill: #CED3D9;
  stroke: #CED3D9;
}
.Quality .icon.secondary .cls-1 {
  fill: #e57373;
}
.Reference .icon.secondary .cls-1 {
  fill: #00BCD4;
}
.Accurity .icon.secondary .cls-1 {
  fill: #CED3D9;
}
.Quality .icon.secondary .cls-1 {
  stroke: #e57373;
}
.Reference .icon.secondary .cls-1 {
  stroke: #00BCD4;
}
.Accurity .icon.secondary .cls-1 {
  stroke: #CED3D9;
}
.icon.xs {
  width: 12px;
  height: 12px;
}
.icon.sm {
  width: 20px;
  height: 20px;
}
.icon.md-24 {
  width: 24px;
  height: 24px;
}
.icon.md {
  width: 28px;
  height: 28px;
}
.icon.lg {
  width: 36px;
  height: 36px;
}
.icon.xl {
  width: 45px;
  height: 45px;
}
.icon.xxl {
  width: 90px;
  height: 90px;
}
.icon.listButton {
  width: 40px;
  height: 40px;
}
.icon.light {
  stroke-width: 0;
}
.icon.normal {
  stroke-width: 2;
}
.icon.bold {
  stroke-width: 4;
}
.ignore-cls-1 .cls-1 {
  fill: currentColor;
}
svg {
  overflow: visible;
}
.gaugeChart {
  font-weight: 200;
}
.recharts-tooltip-wrapper {
  z-index: 9;
  visibility: visible !important;
}
.gaugeChart__tooltip {
  padding: 5px;
  border-left: 5px solid;
  background-color: #fff;
  box-shadow: 0 1px 2px #aaa;
}
.gaugeChart__patchIcon {
  position: absolute;
  width: 20px;
}
.gaugeChart__patchIcon.icon.failed .cls-1 {
  fill: #689f38;
}
.gaugeChart__patchIcon.icon.passed .cls-1 {
  fill: #D5282B;
}
.DropDown {
  font-weight: 300;
  position: absolute;
  z-index: 1003;
  min-width: 200px;
  box-shadow: 0 1px 3px 1px #aaa;
  background-color: #FFFFFF;
}
.DropDown .DropDown__menuItem--empty > div {
  color: #B6BDC6;
  text-align: center;
  cursor: default;
}
.DropDown .DropDown__groupItem {
  padding: 0 8px;
  display: flex;
}
.DropDown .DropDown__groupItem * {
  vertical-align: middle;
}
.DropDown .DropDown__groupItem .icon {
  padding: 10px 0;
  margin-right: 10px;
  display: inline-block;
}
.DropDown.inverted {
  background-color: #303030;
  box-shadow: 0 1px 3px 1px #061220;
}
.DropDown.inverted .DropDown__menuItem {
  color: #FDFDFD;
  background-color: #303030;
}
.DropDown.inverted .DropDown__menuItem:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.DropDown.inverted .DropDown__menuItem.DropDown__menuItem--empty > div {
  color: #FDFDFD;
}
.DropDown.inverted .DropDown__groupItem {
  color: #FDFDFD;
}
.SingleLineField {
  font-weight: 300;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  position: relative;
}
.SingleLineField.SingleLineField--inverted .MuiFormLabel-root {
  color: #FDFDFD;
}
.SingleLineField.SingleLineField--inverted .MuiInputBase-input {
  color: #FDFDFD;
  -webkit-text-fill-color: #FDFDFD;
}
.SingleLineField.SingleLineField--inverted .MuiIconButton-root {
  color: #FDFDFD;
}
.SingleLineField.SingleLineField--inverted .MuiInput-underline:before {
  border-color: #FDFDFD;
}
.SingleLineField.validDragDropTargetHovered .MuiInput-underline:before {
  border-bottom: 2px solid #6D8CE2;
}
.Quality .SingleLineField.validDragDropTargetHovered .MuiInput-underline:before {
  border-bottom: 2px solid #81bb26;
}
.Reference .SingleLineField.validDragDropTargetHovered .MuiInput-underline:before {
  border-bottom: 2px solid #ffb500;
}
.Accurity .SingleLineField.validDragDropTargetHovered .MuiInput-underline:before {
  border-bottom: 2px solid #6D8CE2;
}
.SingleLineField .SingleLineField__icon {
  width: 56px;
  min-width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SingleLineField .SingleLineField__icon .MuiIconButton-root {
  padding: 8px;
  border: 2px solid transparent;
}
.SingleLineField .SingleLineField__icon.validDragDropTarget .MuiIconButton-root {
  border: 2px dashed;
  color: #6D8CE2;
}
.SingleLineField .SingleLineField__icon.validDragDropTargetHovered .MuiIconButton-root {
  border: 2px solid;
  color: white;
  background-color: #6D8CE2;
  border-color: #6D8CE2;
}
.SingleLineField .MuiInputAdornment-root {
  z-index: 1004;
}
.SingleLineField .MuiInput-formControl {
  margin-top: 16px;
}
.multiLineField {
  padding: 10px 0;
  position: relative;
}
.ReferenceField .ReferenceField__content {
  position: relative;
}
.ReferenceField .ReferenceField__content.withIcon .SingleLineField__content {
  width: calc(100% -  56px);
}
.ReferenceField .ReferenceField__content.withIcon .DropDown {
  left: 56px;
  min-width: calc(100% -  56px);
}
.ReferenceField .ReferenceField__content .DropDown.ReferenceField__DropDown--up {
  top: auto;
  bottom: 90px;
}
.ReferenceField .ReferenceField__content .DropDown {
  top: 62px;
  left: 0;
  right: 0;
}
.referenceFieldDropArea {
  position: absolute;
  opacity: 0.4;
  width: 100%;
  height: 36px;
  top: 18px;
  z-index: 1;
}
.collectionRowDropBoxExpander,
.collectionRowDropBoxExpanderHidden {
  text-align: center;
  transition: all 0.4s;
}
.Quality .collectionRowDropBoxExpander,
.Quality .collectionRowDropBoxExpanderHidden {
  background-color: rgba(129, 187, 38, 0.33);
}
.Reference .collectionRowDropBoxExpander,
.Reference .collectionRowDropBoxExpanderHidden {
  background-color: rgba(255, 181, 0, 0.33);
}
.Accurity .collectionRowDropBoxExpander,
.Accurity .collectionRowDropBoxExpanderHidden {
  background-color: #E2E8F9;
  /* primary-100 */
}
.collectionRowDropBoxExpander {
  border-color: #6D8CE2;
  fill: #6D8CE2;
  stroke: #6D8CE2;
  border: 2px solid;
  border-radius: 5px;
  height: 60px;
  margin: 15px 20px;
  transform: scaleY(1);
}
.Quality .collectionRowDropBoxExpander {
  border-color: #81bb26;
  fill: #81bb26;
  stroke: #81bb26;
}
.Reference .collectionRowDropBoxExpander {
  border-color: #ffb500;
  fill: #ffb500;
  stroke: #ffb500;
}
.Accurity .collectionRowDropBoxExpander {
  border-color: #6D8CE2;
  fill: #6D8CE2;
  stroke: #6D8CE2;
}
.collectionRowDropBoxExpanderHidden {
  border: 0px;
  height: 0px;
  margin: 0px 20px;
  transform: scaleY(0);
}
.collectionFieldDropBox,
.collectionFieldDropBoxHovered {
  border-color: #6D8CE2;
  fill: #6D8CE2;
  stroke: #6D8CE2;
  margin: 15px 20px;
  height: 60px;
  text-align: center;
  border: 2px dashed;
  border-radius: 5px;
  background: #fff;
}
.Quality .collectionFieldDropBox,
.Quality .collectionFieldDropBoxHovered {
  border-color: #81bb26;
  fill: #81bb26;
  stroke: #81bb26;
}
.Reference .collectionFieldDropBox,
.Reference .collectionFieldDropBoxHovered {
  border-color: #ffb500;
  fill: #ffb500;
  stroke: #ffb500;
}
.Accurity .collectionFieldDropBox,
.Accurity .collectionFieldDropBoxHovered {
  border-color: #6D8CE2;
  fill: #6D8CE2;
  stroke: #6D8CE2;
}
.collectionFieldDropBoxHovered {
  border: 2px solid;
  border-radius: 5px;
}
.Quality .collectionFieldDropBoxHovered {
  background-color: rgba(129, 187, 38, 0.33);
}
.Reference .collectionFieldDropBoxHovered {
  background-color: rgba(255, 181, 0, 0.33);
}
.Accurity .collectionFieldDropBoxHovered {
  background-color: #E2E8F9;
  /* primary-100 */
}
.collectionFieldDropBoxIcon {
  margin-top: 10px;
}
.collectionFieldDropBoxIcon.icon {
  display: inline-block;
}
.collectionFieldDropBoxIcon.icon .cls-1 {
  border-color: #6D8CE2;
  fill: #6D8CE2;
  stroke: #6D8CE2;
}
.Quality .collectionFieldDropBoxIcon.icon .cls-1 {
  border-color: #81bb26;
  fill: #81bb26;
  stroke: #81bb26;
}
.Reference .collectionFieldDropBoxIcon.icon .cls-1 {
  border-color: #ffb500;
  fill: #ffb500;
  stroke: #ffb500;
}
.Accurity .collectionFieldDropBoxIcon.icon .cls-1 {
  border-color: #6D8CE2;
  fill: #6D8CE2;
  stroke: #6D8CE2;
}
.switchFieldWrapper {
  position: relative;
  padding: 10px 0;
  box-sizing: border-box;
}
.switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked {
  color: #6D8CE2;
}
.Quality .switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked {
  color: #81bb26;
}
.Reference .switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked {
  color: #ffb500;
}
.Accurity .switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked {
  color: #6D8CE2;
}
.switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #6D8CE2;
  opacity: 0.5;
}
.Quality .switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #81bb26;
}
.Reference .switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #ffb500;
}
.Accurity .switchFieldWrapper.revisionColoring .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #6D8CE2;
}
.switchFieldWrapper.revisionColoring .MuiFormControlLabel-label {
  color: #061220;
}
.switchField {
  height: 100%;
  min-height: 48px;
}
.switchField.switchField--inverted {
  color: #ffffff;
}
.switchField.switchField--inverted .MuiSwitch-track {
  background-color: #ffffff !important;
}
.switchField.switchField--error {
  color: #D5282B;
}
.switchField.switchField--error a {
  color: #D5282B;
}
.switchField__errorMessage {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  color: #D5282B;
  white-space: nowrap;
}
.FullScreenOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1003;
}
.accurity-tooltip,
.__react_component_tooltip.accurity-tooltip {
  max-width: 300px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  z-index: 1099;
}
.accurity-tooltip > div,
.__react_component_tooltip.accurity-tooltip > div {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
}
.autoTruncatedLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.inlineFlexibleLabel {
  display: inline-block;
  white-space: nowrap;
}
.basicLabel,
.numericLabel {
  color: #061220;
}
.basicLabel.inverted,
.numericLabel.inverted {
  color: #FDFDFD;
}
.spinner {
  width: 50px;
  height: 50px;
  animation: rotate 2s infinite ease-in-out;
}
.spinner.xs {
  width: 12px;
  height: 12px;
}
.spinner.sm {
  width: 20px;
  height: 20px;
}
.spinner.md {
  width: 28px;
  height: 28px;
}
.spinner.lg {
  width: 36px;
  height: 36px;
}
.spinner.xl {
  width: 45px;
  height: 45px;
}
.spinner.xxl {
  width: 90px;
  height: 90px;
}
@keyframes rotate {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: perspective(120px) rotateX(0deg) rotateY(180deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
  }
}
.loader {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 80px;
}
.loader.xs {
  min-height: 30px;
}
.loader.sm {
  min-height: 40px;
}
.loader.md {
  min-height: 60px;
}
.loader.lg {
  min-height: 90px;
}
.spinnerOverlay {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
  z-index: 1099;
  overflow: hidden;
}
.spinnerOverlay.hidden {
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
table.widget {
  font-weight: 200;
  color: #061220;
  width: 320px;
  height: 225px;
  border-collapse: collapse;
  table-layout: fixed;
}
table.widget.selected {
  border: 2px solid #6D8CE2;
}
.Quality table.widget.selected {
  border: 2px solid #81bb26;
}
.Reference table.widget.selected {
  border: 2px solid #ffb500;
}
.Accurity table.widget.selected {
  border: 2px solid #6D8CE2;
}
table.widget .widget__body {
  font-size: 20px;
}
table.widget .widget__body td {
  border: 1px solid #E7E9EC;
}
table.widget thead {
  border: 1px solid #E7E9EC;
}
table.widget tbody td {
  position: relative;
  padding: 5px;
}
table.widget .widget__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
table.widget .widget__header .basicLabel {
  overflow: hidden;
  width: 235px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}
table.widget .widget__label {
  color: #B6BDC6;
  font-size: 12px;
  display: block;
}
table.widget .widget__label.inverted {
  color: #FDFDFD;
}
table.widget .widget__trendArrow {
  position: absolute;
  top: 10px;
  right: 10px;
}
table.widget .widget__footerContainer {
  font-size: 11px;
  height: 25px;
  background-color: #FFFFFF !important;
}
table.widget .widget__footer {
  color: #061220;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
table.widget .widget__footer div {
  display: inline-flex;
  align-items: center;
}
table.widget.clickable {
  cursor: pointer;
}
.widgetTemplatesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.widgetTemplate {
  cursor: move;
  opacity: 1;
  flex: 0 0 180px;
  fill: #777;
}
.widgetTemplate .icon {
  width: 100%;
  height: 100%;
}
.widgetTemplate path.cls-2 {
  fill: white;
}
.widgetTemplate .primary {
  fill: #6D8CE2;
}
.Quality .widgetTemplate .primary {
  fill: #81bb26;
}
.Reference .widgetTemplate .primary {
  fill: #ffb500;
}
.Accurity .widgetTemplate .primary {
  fill: #6D8CE2;
}
.widgetTemplate.disabled {
  opacity: 0.25;
  cursor: default;
}
.arrowWidget .widget__body {
  background-color: #E7E9EC;
}
.arrowWidget .widget__body.passed {
  background-color: #689f38;
}
.arrowWidget .widget__body.failed {
  background-color: #D5282B;
}
.arrowWidget .widget__body.stable {
  background-color: #d7d7d7;
}
.arrowWidget__arrow {
  display: inline-block;
}
.arrowWidget__valueWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowWidget__value {
  font-size: 55px;
  padding: 20px;
  font-weight: 800;
  color: #fff;
  opacity: 0.7;
}
.basicMeasurementWidget__valueWrapper {
  text-align: center;
}
.gaugeWidget .gaugeWidget__trend {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}
.gaugeWidget .gaugeWidget__chart {
  padding-left: 30px;
}
.numericWidget {
  background-color: #aaa;
}
.numericWidget.passed {
  background-color: #689f38;
}
.numericWidget.warning {
  background-color: #F7A600;
}
.numericWidget.failed {
  background-color: #D5282B;
}
.numericWidget .numericWidget__value {
  text-align: center;
  font-size: 50px;
}
.numericWidget .numericWidget__value .numericWidget__recordType {
  font-size: 15px;
}
.numericWidget .numericWidget__value .numericWidget__recordType.records,
.numericWidget .numericWidget__value .numericWidget__recordType.amount {
  display: block;
}
.numericWidget .numericWidget__value .numericWidget_numericLabel {
  word-break: break-all;
}
.numericWidget .numericWidget__patchIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.numericWidget .trendArrow {
  display: block;
}
.numericWidget .trendArrow.topRight {
  position: absolute;
  top: 10px;
  right: 10px;
}
svg {
  overflow: visible;
}
.recharts-tooltip-wrapper {
  visibility: visible !important;
}
.trendlineChart {
  position: relative;
}
.trendlineChart svg {
  overflow: hidden;
}
.trendlineChart #trendlineChart,
.trendlineChart #trendlineChart__values {
  overflow: visible;
}
.trendlineChart .trendlineChart__label {
  font-size: 12px;
  fill: #b4b4b4;
}
.trendlineChart .trendlineChart__tooltipLine {
  stroke: #bdbdbd;
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms linear;
  shape-rendering: crispedges;
}
.trendlineChart .trendlineChart__tooltipLine.hidden {
  visibility: hidden;
  opacity: 0;
}
.trendlineChart #trendlineChart__passedArea {
  fill: rgba(0, 0, 0, 0.05);
}
.trendlineChart #trendlineChart__warningArea {
  fill: rgba(0, 0, 0, 0.05);
}
#trendlineChart__passedLine line,
#trendlineChart__warningLine line {
  stroke: #bdbdbd;
  stroke-width: 1;
}
.trendlineChartTooltip {
  color: #061220;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  border-left: 5px solid #000;
  box-shadow: 0 1px 3px 1px #aaa;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 1099;
}
.trendlineChartTooltip.hidden {
  visibility: hidden;
  opacity: 0;
}
.trendlineChartTooltip.passed {
  border-left-color: #689f38;
}
.trendlineChartTooltip.warning {
  border-left-color: #F7A600;
}
.trendlineChartTooltip.failed {
  border-left-color: #D5282B;
}
.trendlineChartTooltip.none {
  border-left-color: #888;
}
.trendlineChartTooltip .trendlineChartTooltip__label {
  font-size: 9px;
  position: absolute;
  right: 5px;
  top: 2px;
}
.trendlineChartTooltip .trendlineChartTooltip__values {
  padding: 10px 5px 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: baseline;
}
.trendlineChartTooltip .trendlineChartTooltip__values .value {
  flex-grow: 2;
  font-size: 22px;
}
.trendlineChartTooltip .trendlineChartTooltip__values .threshold {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.trendlineChartTooltip .trendlineChartTooltip__footer {
  font-size: 12px;
  border-top: 1px solid #B6BDC6;
  padding: 2px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.line.passed {
  stroke: #689f38;
}
.line.warning {
  stroke: #F7A600;
}
.line.failed {
  stroke: #D5282B;
}
.line.none {
  stroke: #888;
}
.point {
  fill: #fff;
  transition: all 100ms ease-in;
}
.point.passed {
  stroke: #689f38;
}
.point.warning {
  stroke: #F7A600;
}
.point.failed {
  stroke: #D5282B;
}
.point.none {
  stroke: #888;
}
.point.passed.hovered {
  stroke: #689f38;
  fill: #689f38;
}
.point.warning.hovered {
  stroke: #F7A600;
  fill: #F7A600;
}
.point.failed.hovered {
  transition: all 100ms ease-in;
  stroke: #D5282B;
  fill: #D5282B;
}
.point.none.hovered {
  stroke: #888;
  fill: #888;
}
.point.noFill {
  fill: transparent;
}
.point.noStroke {
  stroke: transparent;
}
.trendlineChartGoal {
  fill: #689f38;
  stroke: #689f38;
}
.trendlineWidget__NA {
  text-align: center;
  font-size: 35px;
  padding-bottom: 31px;
}
/*

  https://material.io/guidelines/components/text-fields.html#text-fields-input-types

  default state

  - 22px top padding
  - 16px placeholder text
  - 22px bottom padding
  - 1px border padding
  - 1px border
  - 8px helper text
  - 12px text size
  - 8px bottom padding

  total: 90px

  focused:

  - 16px top padding
  - 12px Label
  - 8px
  - 16px Text size
  - 8px
  - 2px border
  - 8px
  - 12px helper text
  - 8px

  total: 90px
 */
.Selector .Selector__content {
  position: relative;
  height: 90px;
}
.Selector .Selector__content.withIcon .SelectorMenu {
  left: 56px;
}
.Selector .Selector__content .SelectorMenu.Selector__SelectorMenu--up {
  top: auto;
  bottom: 90px;
}
.Selector .Selector__content .SelectorMenu {
  top: 62px;
  left: 0;
  right: 0;
}
.FilterBox {
  position: absolute;
  display: flex;
  font-weight: 300;
  z-index: 1004;
  font-size: 16px;
  height: 86px;
  width: 330px;
}
.FilterBox .FilterBox__content {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
.FilterBox:hover input {
  border-bottom: 2px solid #8691A0;
  padding-bottom: 8px;
}
.FilterBox label,
.FilterBox input {
  transition: all 300ms;
  touch-action: manipulation;
}
.FilterBox label {
  color: #B6BDC6;
  font-size: 16px;
}
.FilterBox input {
  font-weight: 300;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #B6BDC6;
  background-color: inherit;
  -webkit-appearance: menulist;
  border-radius: 0;
  padding: 8px 10px 9px 0;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.FilterBox input:focus {
  outline: 0;
  -webkit-appearance: none;
  transition: border-width 500ms ease-in-out;
  padding-bottom: 8px;
  border-bottom: 2px solid #6D8CE2;
}
.Quality .FilterBox input:focus {
  border-bottom: 2px solid #81bb26;
}
.Reference .FilterBox input:focus {
  border-bottom: 2px solid #ffb500;
}
.Accurity .FilterBox input:focus {
  border-bottom: 2px solid #6D8CE2;
}
.FilterBox input:placeholder-shown + label {
  cursor: text;
  transform-origin: left bottom;
  transform: translate(0, calc(14px + 8px));
}
.FilterBox ::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
.FilterBox input:focus::-webkit-input-placeholder {
  opacity: 1;
}
.FilterBox input:not(:placeholder-shown) + label,
.FilterBox input:focus + label {
  transform: translate(0, 0);
  cursor: pointer;
  font-size: 12px;
}
.FilterBox input:not(:placeholder-shown):focus + label,
.FilterBox input:focus + label {
  color: #6D8CE2;
}
.Quality .FilterBox input:not(:placeholder-shown):focus + label,
.Quality .FilterBox input:focus + label {
  color: #81bb26;
}
.Reference .FilterBox input:not(:placeholder-shown):focus + label,
.Reference .FilterBox input:focus + label {
  color: #ffb500;
}
.Accurity .FilterBox input:not(:placeholder-shown):focus + label,
.Accurity .FilterBox input:focus + label {
  color: #6D8CE2;
}
.FilterBox .FilterBox--helperMessage {
  font-size: 12px;
  height: 28px;
  display: flex;
  align-items: center;
}
.FilterBox.FilterBox--error input {
  border-color: #D5282B;
}
.FilterBox.FilterBox--error input:focus + label {
  color: #D5282B;
}
.FilterBox.FilterBox--error .FilterBox--helperMessage {
  color: #D5282B;
}
.FilterBox.FilterBox--disabled input,
.FilterBox.FilterBox--disabled input:hover {
  border-bottom: 1px dashed #B6BDC6;
  padding-bottom: 9px;
}
.SelectorMenu {
  font-weight: 300;
  position: absolute;
  overflow: hidden;
  z-index: 1005;
  min-width: 330px;
  box-shadow: 0 1px 3px 1px #aaa;
  background-color: #FFFFFF;
  max-height: 352px;
  overflow-y: auto;
}
.SelectorMenu .isvg.loaded.icon svg {
  display: block;
  height: 30px;
  width: 30px;
}
.SelectorMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 330px;
}
/* checkbox */
.SelectAllItemWrapper {
  height: 70px;
}
.SelectAllItemWrapper .SelectAllItem {
  background-color: #ffffff;
  border-bottom: 1px solid #696969;
  position: fixed;
  z-index: 1010;
}
.SelectAllItemWrapper .SelectAllItem:hover {
  cursor: pointer;
  background-color: #ffffff;
}
.MenuItemListItem {
  height: 70px;
}
.MenuItemListItem:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
.MenuItemListItem .MenuItemContainer {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 70px;
  padding: 10px 0;
  position: relative;
  width: 100%;
}
.MenuItemListItem .MenuItemContainer .CheckboxWrapper {
  height: 50px;
  width: 50px;
  display: inline;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
}
.MenuItemListItem .MenuItemContainer .CheckboxWrapper .CheckMark {
  display: inline-block;
  height: 20px;
  width: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.MenuItemListItem .MenuItemContainer .CheckboxWrapper .CheckMark:after {
  content: "";
  display: block;
  position: relative;
  left: 4px;
  width: 5px;
  height: 8.5px;
  top: 1px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.MenuItemListItem .MenuItemContainer .CheckboxChecked {
  border: 2px solid #6D8CE2;
  background-color: #6D8CE2;
  border-radius: 3px;
}
.Quality .MenuItemListItem .MenuItemContainer .CheckboxChecked {
  border: 2px solid #81bb26;
}
.Reference .MenuItemListItem .MenuItemContainer .CheckboxChecked {
  border: 2px solid #ffb500;
}
.Accurity .MenuItemListItem .MenuItemContainer .CheckboxChecked {
  border: 2px solid #6D8CE2;
}
.Quality .MenuItemListItem .MenuItemContainer .CheckboxChecked {
  background-color: #81bb26;
}
.Reference .MenuItemListItem .MenuItemContainer .CheckboxChecked {
  background-color: #ffb500;
}
.Accurity .MenuItemListItem .MenuItemContainer .CheckboxChecked {
  background-color: #6D8CE2;
}
.MenuItemListItem .MenuItemContainer .CheckboxPartiallyChecked {
  border: 2px solid #696969;
  background-color: #696969;
  border-radius: 3px;
}
.MenuItemListItem .MenuItemContainer .CheckboxUnchecked {
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 3px;
}
.MenuItemListItem .MenuItemContainer .ItemName {
  float: left;
  height: 50px;
  padding: 16px 0 14px 0;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.MenuItemListItem .IconContainer {
  position: relative;
  display: inline-block;
  float: right;
  width: 50px;
  height: 50px;
  padding: 12px 10px 8px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.MenuItemListItem .ItemIcon {
  height: 30px;
  width: 30px;
}
.MenuItemListItem .greyBackgroundIcon {
  background-color: grey;
  border-radius: 50%;
}
.accDataTable {
  font-size: 14px;
  position: relative;
  border-collapse: collapse;
  word-break: break-all;
  table-layout: fixed;
  width: 100%;
}
.accDataTable .accDataTable__headerCell {
  font-weight: 500;
  color: #8691A0;
  white-space: nowrap;
}
.accDataTable td,
.accDataTable th {
  user-select: none;
  text-align: left;
  overflow: hidden;
}
.accDataTable .headerCellDragSource {
  cursor: pointer;
}
.accDataTable tbody tr {
  height: 42px;
  border-bottom: 1px solid #E7E9EC;
}
.accDataTable tbody tr:hover {
  background: #F3F4F5;
  cursor: pointer;
}
.accDataTable tbody tr.highlighted {
  background-color: #CED3D9;
}
.accDataTable tbody td {
  color: #061220;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
}
.customLayer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.handle {
  cursor: pointer !important;
}
.cell {
  display: flex;
  margin-left: 0;
  transition: margin-left 200ms ease-in-out;
}
.cell .ellipsis {
  width: 100%;
}
.cell .headerCell__label {
  display: flex;
  min-height: 20px;
  line-height: 20px;
}
.cell .headerCell__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px 2px 2px;
  width: 14px;
}
.cell .graphicCell,
.cell .graphicCell span {
  margin: auto;
  text-align: center;
}
.cell .numberCell,
.cell .dateCell {
  text-align: right;
}
.cell .alignCellRight {
  text-align: right;
}
.cell .alignCellLeft {
  text-align: left;
}
.cell .alignCellCenter {
  text-align: center;
}
.cell .hyperlinkColumnCell a:visited {
  color: blue;
}
.draggedCell {
  background-color: #ffffff;
  border: 1px solid lightgray;
  border-radius: 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  color: #8691A0;
  min-height: 24px;
  line-height: 24px;
  font-size: 12px;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.draggedCell * {
  float: left;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
thead .filters > * {
  padding: 0 5px;
}
.draggedCell .dragDots {
  margin-right: 10px;
}
.dragDotsTableColumn {
  margin-top: 1px;
}
.columnFilter {
  height: 32px;
  margin-bottom: 8px !important;
}
.columnFilter:before {
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  border-bottom: 1px solid #E7E9EC !important;
}
.columnFilter.columnFilter--withComparisonSelector {
  position: relative;
}
.columnFilter.columnFilter--withComparisonSelector input {
  padding-left: 45px !important;
}
.columnFilter .MuiSelect-icon {
  color: #8691A0;
}
.columnFilter .MuiInput-underline:before {
  display: none;
}
.comparisonSelector {
  min-width: 45px;
  position: absolute;
  z-index: 2;
}
.comparisonSelector .MuiSelect-select {
  padding-right: 28px;
}
.comparisonSelector .MuiSelect-icon {
  margin-right: 3px;
  color: #E7E9EC;
}
.boolFilter,
.boolFilterMenuItem {
  text-align: left;
}
.boolFilterMenuItem {
  width: 100%;
}
.dateFilter .timeFilter__timePicker,
.timeFilter .timeFilter__timePicker {
  margin-bottom: 8px;
}
.dateFilter .timeFilter__timePicker input,
.timeFilter .timeFilter__timePicker input {
  padding-left: 50px;
}
.iconColumn {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.iconColumn.passed {
  color: #689f38;
}
.iconColumn.warning {
  color: #F7A600;
}
.iconColumn.failed {
  color: #D5282B;
}
.iconColumn.double-icon-centered {
  width: 56px;
  height: 28px;
}
.iconColumn.double-icon-centered svg {
  height: 28px;
}
.iconFilter *:not(button) {
  padding-right: 0 !important;
}
.iconFilter .icon.iconColumn {
  display: block;
  margin-top: -4px;
  margin-bottom: -5px;
}
.iconFilter__optionAll {
  text-align: center;
  width: 100%;
}
.percentageFilter input,
.numberFilter input {
  text-align: right;
  -moz-appearance: textfield;
}
.percentageFilter input::-webkit-outer-spin-button,
.numberFilter input::-webkit-outer-spin-button,
.percentageFilter input::-webkit-inner-spin-button,
.numberFilter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.columnFilter__dropDownSelector .MuiMenuItem-root {
  min-height: 36px;
}
.TagColumnCell .MuiChip-root {
  margin-bottom: 0;
}
.accDataTable__checkboxCell {
  width: 34px;
}
.accDataTable__checkboxCell .MuiCheckbox-root {
  padding: 0;
}
.accDataTable__checkboxCell .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root {
  fill: #B6BDC6;
}
tbody tr:hover .accDataTable__checkboxCell .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root {
  fill: #888;
}
.accDataTable__checkboxCell .MuiCheckbox-indeterminate .MuiSvgIcon-root {
  fill: #888;
}
.miniChart__chart {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 50px;
  border: 2px solid #d7d7d7;
}
.miniChart__chart.passed {
  border-color: #689f38;
}
.miniChart__chart.failed {
  border-color: #D5282B;
}
.miniChart__label {
  margin-bottom: 5px;
}
.miniChart__trendArrow {
  width: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background-color: #d7d7d7;
}
.miniChart__trendArrow.passed {
  background-color: #689f38;
}
.miniChart__trendArrow.failed {
  background-color: #D5282B;
}
.miniChart__trendValue {
  width: 100%;
  font-size: 16px;
  display: block;
  text-align: center;
  font-weight: 600;
}
.miniChart__area .recharts-area-area {
  fill-opacity: 0.3;
  fill: #d7d7d7;
}
.miniChart__area .recharts-area-curve {
  stroke: #d7d7d7;
}
.miniChart__area.passed .recharts-area-area {
  fill-opacity: 0.3;
  fill: #689f38;
}
.miniChart__area.passed .recharts-area-curve {
  stroke: #689f38;
}
.miniChart__area.failed .recharts-area-area {
  fill-opacity: 0.3;
  fill: #D5282B;
}
.miniChart__area.failed .recharts-area-curve {
  stroke: #D5282B;
}
.miniCharts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 30px;
}
.miniCharts .miniChart {
  margin-top: auto;
  width: 22%;
}
.miniCharts .miniChart .chartAreaResponsiveContainer {
  display: flex;
  align-items: center;
}
.staticField {
  height: auto;
  font-size: 16px;
  display: -webkit-flex;
  display: flex;
}
.staticField.iconButton {
  margin-right: 7px;
}
.staticField.inverted {
  color: #FDFDFD;
}
.staticField.inverted label {
  color: #FDFDFD;
}
.staticField.inverted input {
  color: #FDFDFD;
  -webkit-text-fill-color: #FDFDFD;
}
.staticField.scrollBarAfterFiveLines div div {
  overflow-y: auto;
  text-overflow: initial;
  max-height: 115px;
  word-break: break-all;
}
.staticField input {
  cursor: initial;
  -webkit-text-fill-color: inherit;
}
.staticField label {
  color: #8691A0;
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 14px;
}
.staticField .staticField__contentWrapper {
  padding: 10px 0;
  max-width: 100%;
}
.staticField .staticField__content {
  margin-top: 7px;
  width: 100%;
}
.staticField .staticField__content div {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.accuritySnackbar {
  max-width: 416px;
  left: 12px;
  bottom: 12px;
}
.accuritySnackbar .MuiSnackbarContent-root {
  padding-right: 12px;
  background-color: #061220;
  min-width: 300px;
}
.accuritySnackbar .MuiSnackbarContent-message {
  max-width: 85%;
  max-height: 40px;
  padding: 0;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.accuritySnackbar .action {
  padding-left: 0;
}
.resizerHolder {
  cursor: ew-resize;
  padding: 0 4px;
}
.resizerHolder .resizer {
  height: 100%;
  position: relative;
  width: 2px;
}
.FileChooser {
  font-weight: 300;
}
.FileChooser__fileList {
  list-style: none;
  margin-top: 0.5em;
  padding: 0;
}
.FileChooser__fileList li {
  line-height: 1.5em;
}
.FileChooser__fileListLabel {
  display: inline-block;
  color: #B6BDC6;
  font-size: 12px;
  white-space: nowrap;
  margin-top: 14px;
}
.FileChooser__button {
  margin: 8px 0;
}
.FileChooser.revisionColoring::before {
  top: -10px;
  bottom: -10px;
}
.FileChooser.revisionColoring::after {
  top: -10px;
  bottom: -10px;
}
.richTextEditorWrapper {
  position: relative;
  padding-bottom: 12px;
}
.RichEditor-root {
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #6D8CE2;
}
.Quality .RichEditor-root {
  border: 1px solid #81bb26;
}
.Reference .RichEditor-root {
  border: 1px solid #ffb500;
}
.Accurity .RichEditor-root {
  border: 1px solid #6D8CE2;
}
.RichEditor-root.readOnly {
  border: 1px solid #ddd;
  max-height: 120px;
  overflow-y: scroll;
}
.richTextEditorWrapper.expanded .RichEditor-root {
  max-height: unset;
}
.richTextEditorWrapper.dropHovered .RichEditor-root {
  border: 1px solid #6D8CE2;
}
.richTextEditorWrapper.disabled:not(.revisionColoring) .RichEditor-root.readOnly {
  border: 1.5px dotted rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3);
}
.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  padding: 8px 12px;
}
.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.RichEditor-root.readOnly .public-DraftEditor-content {
  min-height: auto;
}
.RichEditor-ControlsRoot {
  background-color: #E7E9EC;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 15px;
  border-radius: 5px 5px 0 0;
  position: relative;
}
.RichEditor-Control {
  height: auto;
  min-height: 32px;
}
.RichEditor-Control svg {
  height: auto;
  min-height: 32px;
  margin: 0 5px;
}
.RichEditor-Control {
  display: inline-block;
}
.RichEditor-Control--customControls > * {
  display: inline-block;
}
.RichEditor-Control--customControls svg {
  width: 28px;
  padding: 4px;
}
.RichEditor-ParagraphControls {
  display: inline-block !important;
}
.RichEditor-ParagraphControls .MuiSelect-icon {
  margin-top: -5px;
  padding: 0!important;
}
.RichEditor-ParagraphControls--paragraph {
  font-weight: normal;
  font-size: 12px;
}
.RichEditor-ParagraphControls--header1 {
  font-weight: bold;
  font-size: 18px;
}
.RichEditor-ParagraphControls--header2 {
  font-weight: bold;
  font-size: 16px;
}
.RichEditor-ParagraphControls--header3 {
  font-weight: bold;
  font-size: 14px;
}
.RichEditor-alignLeft {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.RichEditor-alignCenter {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.RichEditor-alignRight {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.RichEditor-alignJustify {
  display: inline-block;
  width: 100%;
  text-align: justify;
}
.RichEditor-CloseEditor {
  position: absolute;
  right: 10px;
  top: 10px;
}
.RichEditor-singleControl:hover {
  cursor: pointer;
}
.RichEditor-openChipListIcon svg path {
  fill: rgba(0, 0, 0, 0.54) !important;
}
.RichEditor-root .MuiChip-root {
  max-width: 300px;
}
.RichEditor-expandCollapseButtonWrapper {
  background: white;
  position: sticky;
  text-align: center;
  bottom: 0;
}
.revisionColoring--red .RichEditor-expandCollapseButtonWrapper {
  background: #ffe5e5;
}
.revisionColoring--green .RichEditor-expandCollapseButtonWrapper {
  background: #e5ffe9;
}
.RichEditor-expandCollapseButton {
  color: #B6BDC6;
}
.columnToggler {
  position: relative;
}
.columnToggler__icon {
  margin: -5.5px;
}
.columnToggler__list {
  max-height: 300px;
  width: 360px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.columnToggler__listItemIcon {
  justify-content: flex-end;
}
.columnToggler__listItem--indented {
  padding-left: 40px;
}
.paginator {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  font-weight: 300;
  font-size: 12px;
  color: #8691A0;
  height: 70px;
}
.paginator .paginator__perPage {
  padding: 0 5px;
}
.paginator .paginator__perPageSelector {
  font-size: 12px !important;
  min-width: 50px;
}
.paginator .paginator__buttons {
  margin-left: 8px;
}
.welcomeScreen__container {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 10px 10px 0;
  border-radius: 5px;
  background: white;
  min-width: 500px;
  min-height: 600px;
}
.welcomeScreen__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -100px;
}
.welcomeScreen__content > img {
  margin-bottom: 40px;
  height: 80px;
}
.welcomeScreen__menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.welcomeScreen__menuItem {
  font-weight: 300;
  font-size: 13px;
  color: #061220;
  width: 100px;
}
.welcomeScreen__container .globalSearch__wrapper {
  margin-bottom: 30px;
}
.welcomeScreen__container .globalSearch__wrapper .MuiAutocomplete-listbox {
  /*
    the structure of welcome page is:
        64px   AppBar
        10px   content margin
        x      blank space above logo
        328px  content
               - 60px of 328px takes bottom margin of global search component
        x      blank space below global search
        10px   content margin

     maximum listBox height is a space under global search component:
        bottom margin of GS component + x - padding/margin of the dropdown
     */
  max-height: calc(60px + calc((100vh - 64px - 2 * 10px - 328px) / 2) - 34px);
}
.registrationScreen__container {
  display: flex;
  min-height: 100vh;
  color: #061220;
  background-color: #E7E9EC;
  background-repeat: no-repeat;
}
.registrationScreen__container a,
.registrationScreen__container a:visited {
  color: #061220;
}
.registrationScreen__container a:hover,
.registrationScreen__container a:visited:hover {
  color: #6D8CE2;
}
.Quality .registrationScreen__container a:hover,
.Quality .registrationScreen__container a:visited:hover {
  color: #81bb26;
}
.Reference .registrationScreen__container a:hover,
.Reference .registrationScreen__container a:visited:hover {
  color: #ffb500;
}
.Accurity .registrationScreen__container a:hover,
.Accurity .registrationScreen__container a:visited:hover {
  color: #6D8CE2;
}
.registrationScreen__container--loginForm {
  background-image: url('https://a.storyblok.com/f/91820/x/bd5e003b0b/business-glossary-app-login-page-lines.svg');
  background-position: 65% 25%;
}
.registrationScreen__container--signUpForm {
  background-image: url('https://a.storyblok.com/f/91820/x/4d9f09b4f5/business-glossary-app-sign-up-page-lines.svg');
  background-position: 65% 5%;
}
.registrationScreen__container--thankYou {
  background-image: url('https://a.storyblok.com/f/91820/x/2266a42738/acw-business-glossary-thank-you-signing-up-lines.svg');
  background-position: left 725px top -125px;
}
.registrationScreen__container--welcomeBack {
  background-image: url('https://a.storyblok.com/f/91820/x/8bce31ca0c/acw-business-glossary-login-lines.svg');
  background-position: left 15%;
}
.registrationScreen__content {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.registrationInfoBox__container {
  position: absolute;
  top: 0;
  left: 20%;
  margin-right: 20%;
  max-width: 530px;
}
.registrationInfoBox__container .registrationLogo__container {
  width: auto;
  text-align: left;
  margin: 40px 0 130px;
}
.registrationInfoBox__container .registrationFormTitle {
  width: auto;
  text-align: left;
  margin: 0 0 48px;
  font-size: 43px;
}
.registrationInfoBox__container .registrationStaticText {
  width: auto;
  text-align: left;
  margin: 0 0 48px;
  line-height: 27px;
}
.registrationForm__container {
  width: 512px;
  background-color: #fff;
  box-shadow: 0 0 40px #00000014;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 48px 35px;
  margin: 40px 0;
  font-size: 18px;
  line-height: 27px;
}
.registrationFormRow {
  display: flex;
  width: 100%;
}
.registrationFormRow > * {
  margin: 0 12px;
  flex: 1 0 0;
}
.registrationFormRow :first-child {
  margin-left: 0;
}
.registrationFormRow :last-child {
  margin-right: 0;
}
.registrationStaticText {
  margin: 32px 0 17px;
  font-size: 18px;
  text-align: center;
}
.registrationStaticText.registrationStaticText--finePrint {
  margin: 20px 0 0;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #8691A0;
}
.registrationLogo__container {
  width: 100%;
  text-align: center;
}
.registrationLogo__image {
  max-width: 220px;
}
.registrationFormTitle {
  margin: 0 0 40px;
  width: 100%;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
}
.mainMenu__drawer {
  width: 0;
  margin-top: 10px;
  margin-right: 10px;
}
.mainMenu__drawer--collapsed {
  width: 72px;
  margin-right: 0;
}
.mainMenu__drawer--expanded {
  width: 300px;
}
.mainMenu__paper {
  position: static;
  background-color: transparent;
  border: none;
  overflow: visible;
  width: 290px;
}
.mainMenu__drawer--collapsed .mainMenu__paper {
  width: 72px;
}
.mainMenu__menuGroup:hover {
  background-color: transparent;
}
.mainMenu__menuItem {
  z-index: 999;
  height: 40px;
  border-radius: 0 50px 50px 0;
  transition: none;
  overflow: hidden;
}
.mainMenu__menuItem:hover {
  width: 290px;
  background-color: #CED3D9;
  overflow: visible;
}
.mainMenu__menuItem--selected {
  background-color: #6D8CE2;
}
.Quality .mainMenu__menuItem--selected {
  background-color: #81bb26;
}
.Reference .mainMenu__menuItem--selected {
  background-color: #ffb500;
}
.Accurity .mainMenu__menuItem--selected {
  background-color: #6D8CE2;
}
.mainMenu__menuItem--selected:hover {
  background-color: #6D8CE2;
}
.Quality .mainMenu__menuItem--selected:hover {
  background-color: #81bb26;
}
.Reference .mainMenu__menuItem--selected:hover {
  background-color: #ffb500;
}
.Accurity .mainMenu__menuItem--selected:hover {
  background-color: #6D8CE2;
}
.mainMenu__drawer--collapsed .mainMenu__menuItem--selected {
  background-color: transparent;
}
.mainMenu__drawer--collapsed .mainMenu__menuItem--selected:hover {
  background-color: #6D8CE2;
}
.Quality .mainMenu__drawer--collapsed .mainMenu__menuItem--selected:hover {
  background-color: #81bb26;
}
.Reference .mainMenu__drawer--collapsed .mainMenu__menuItem--selected:hover {
  background-color: #ffb500;
}
.Accurity .mainMenu__drawer--collapsed .mainMenu__menuItem--selected:hover {
  background-color: #6D8CE2;
}
.mainMenu__menuItem .MuiListItemIcon-root {
  min-width: 56px;
}
.mainMenu__menuText {
  color: #061220;
}
.mainMenu__menuText--selected {
  color: #FDFDFD;
}
.accurityPaper {
  position: relative;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 16px;
  box-sizing: border-box;
}
.accurityDetail__container {
  height: calc(100% - 20px);
  margin: 10px 0 10px 0;
  flex-shrink: 0;
  padding-top: 0;
  box-sizing: border-box;
}
.accurityDetail__container.inverted {
  background: #303030;
}
.accurityDetail__container.inverted .accurityDetail__headerTitle {
  color: #FDFDFD;
}
.accurityDetail__progressIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.accurityDetail__header {
  position: sticky;
  display: flex;
  align-items: center;
  z-index: 5;
  top: 0;
  height: 72px;
  margin-left: -16px;
  margin-right: -16px;
  padding-right: 16px;
}
.accurityDetail__headerIcon {
  margin: 0 16px;
}
.accurityDetail__headerField {
  flex: 1 0 0;
}
.accurityDetail__headerTitle {
  font-size: 20px;
}
.detailInlineGroup--indentLeft {
  padding-left: 56px;
}
.detailInlineGroup--indentRight {
  padding-right: 44px;
}
.actionField {
  padding: 10px;
  justify-content: left;
  font-size: 16px;
}
.actionField__icon {
  margin-right: 10px;
}
.list {
  overflow: auto;
  position: relative;
  width: 100%;
  min-width: 400px;
  height: 100%;
  max-height: 100%;
  min-height: 350px;
  background: #fff;
}
.list .list__progressIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}
.list .listContent {
  position: relative;
}
.bulkNotificationsDialog__radioLabel {
  color: #061220;
}
.listPopover {
  min-width: 240px;
}
.listPopover .listPopover__header > span {
  font-weight: bold;
}
.listPopover .listPopover__select {
  font-size: 14px;
  margin-left: 10px;
}
.listPopover .listPopover__actionButtons {
  display: flex;
  justify-content: space-between;
}
.listPopover .listPopover__actionButtons button {
  margin-top: 10px;
  font-weight: 500;
}
.listPopover .FileChooser {
  padding: 16px;
}
.listHeader {
  padding: 16px 16px 0 16px;
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  min-width: fit-content;
  position: sticky;
  background-color: #FFFFFF;
  z-index: 3;
  left: 0;
  top: 0;
}
.listHeader > div {
  margin: 0;
  width: 100%;
}
.listHeader .listHeader__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.listHeader .listHeader__leftNodes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.listHeader .listHeader__icon {
  margin: 0 10px;
}
.listHeader .listHeader__title {
  font-size: 20px;
  font-weight: 300;
  color: #061220;
  white-space: nowrap;
}
.listHeader .listHeader__rightNodes {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.listHeader .listHeader__fabButton {
  color: #ffffff;
}
.listHeader .listHeader__pinFilters {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}
.listFooter {
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: #FFFFFF;
  z-index: 3;
  position: sticky;
  left: 0;
  bottom: 0;
}
.pinFilter {
  margin: 4px;
}
.pinFilter .pinFilter__avatar {
  height: 32px;
  width: 32px;
  margin-left: 0;
}
.pinFilter .pinFilter__avatar:not(.pinFilter__avatar--disabled) {
  background-color: #6D8CE2;
}
.Quality .pinFilter .pinFilter__avatar:not(.pinFilter__avatar--disabled) {
  background-color: #81bb26;
}
.Reference .pinFilter .pinFilter__avatar:not(.pinFilter__avatar--disabled) {
  background-color: #ffb500;
}
.Accurity .pinFilter .pinFilter__avatar:not(.pinFilter__avatar--disabled) {
  background-color: #6D8CE2;
}
.userMessage {
  font-weight: 300;
  font-size: 16px;
  position: relative;
  background-color: #061220;
  color: #FDFDFD;
  width: 300px;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 500px;
  transition: max-height 0.5s ease-in;
  margin-top: 10px;
}
.userMessage.userMessage--collapsed {
  max-height: 3em;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}
.userMessage .userMessage__title {
  font-weight: 500;
}
.userMessage .userMessage__dismiss {
  position: absolute;
  top: 4px;
  right: 4px;
}
.userMessageContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  z-index: 9999;
}
.collectionField__virtualizedListContainer {
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
}
.collectionField__row {
  position: relative;
  min-height: 52px;
}
.collectionField__row:not(.disabled) .collectionField__rowItem {
  padding-right: 44px;
}
.collectionField__rowButton {
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
}
.collectionField__findCandidatesBtn {
  margin: 10px -10px;
  width: calc(100% + 20px);
}
.collectionField__row--suggestion {
  font-weight: 300;
}
.collectionField__row--suggestion .collectionField__rowItem {
  display: flex;
  align-items: center;
  padding-right: 0 !important;
  width: calc(100% - 64px);
}
.collectionField__row--suggestion .circularProgress {
  min-width: 56px;
}
.collectionField__row--suggestion .circularProgress .MuiCircularProgress-root {
  width: 32px !important;
  height: 32px !important;
}
.collectionField__row--suggestion .staticField {
  max-width: calc(100% - 54px);
}
.collectionField__row--suggestion .staticField .staticField__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.collectionField__row--suggestion .collectionField__rowButton {
  display: flex;
  column-gap: 8px;
}
.collapsingGroup {
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
  padding-top: 9px;
  padding-bottom: 9px;
  margin: 0;
}
.collapsingGroup + .collapsingGroup {
  border-top: none;
}
.collapsingGroup__header {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  user-select: none;
}
.collapsingGroup__header:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
}
.collapsingGroup__header > :first-child {
  flex: 1 1 0;
}
.collapsingGroup__header .MuiIconButton-root:hover {
  background-color: unset;
}
.collapsingGroup__header--customHeader:hover {
  cursor: unset;
  background-color: unset;
}
.collapsingGroup__iconAndTitle {
  display: flex;
  align-items: center;
}
.collapsingGroup__iconAndTitle > :first-child {
  margin: 0 10px;
}
.collapsingGroup__body {
  padding: 0 12px;
}
.collapsingGroup__body--inverted {
  background: #303030;
  margin: 10px -8px 0;
  padding: 0 26px;
  border-radius: 4px;
}
.tagField {
  padding: 10px 0;
  position: relative;
  min-width: 330px;
}
.tagField .MuiInputLabel-shrink {
  transform: translate(0, -4.5px) scale(0.75);
}
.tagField.disabled .MuiInput-underline:before,
.tagField.disabled .MuiInput-underline:after {
  border-bottom-width: 2px;
}
.tagField.tagField--revisionColoring .MuiInput-underline:before {
  border-bottom: 1px solid #B6BDC6;
}
.tagChip {
  margin: 0 6px 6px 0;
}
.tagChip.revisionColoringTag {
  opacity: 1;
}
.tagChip.revisionColoringTag--red {
  background-color: #ff7d7d33;
}
.tagChip.revisionColoringTag--green {
  background-color: #26ff471f;
}
.notificationCenter .MuiDrawer-paper {
  width: 400px;
}
.notificationCenterCard__container {
  position: relative;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 5px 10px 0;
}
.notificationCenterCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  transition: border-color 0.5s ease;
}
.notificationCenterCard--unread {
  border: 1px solid #6D8CE2;
}
.Quality .notificationCenterCard--unread {
  border: 1px solid #81bb26;
}
.Reference .notificationCenterCard--unread {
  border: 1px solid #ffb500;
}
.Accurity .notificationCenterCard--unread {
  border: 1px solid #6D8CE2;
}
.notificationCenterCard__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}
.notificationCenterCard__content {
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100% - 30px - 40px);
}
.notificationCenterCard__footer {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.notificationCenterCard__footerLeft {
  display: flex;
  flex: 1;
}
.notificationCenterCard__footerRight {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
}
.notificationCenter__label {
  display: flex;
  justify-content: center;
  padding: 5px 10px 0;
  color: #8691A0;
  font-weight: 300;
}
.notificationCenter__dismissButton {
  position: absolute;
  top: 12px;
  right: 16px;
}
.notificationCenter__dismissAllButton {
  width: 100%;
  padding: 10px;
}
.historyGroupField {
  position: relative;
  min-height: 68px;
  font-weight: 300;
  border-top: 1px solid #E7E9EC;
  border-bottom: 1px solid #E7E9EC;
}
.historyGroupField .historyGroupField__expandIcon {
  position: absolute;
  right: 0;
  top: 14px;
}
.historyGroupField .historyGroupField__header .historyGroupField__changedBy {
  padding-right: 52px;
}
.historyGroupField .MuiGrid-item {
  min-width: 0;
}
.historyGroupField .MuiGrid-container .MuiGrid-item:first-child {
  max-width: 180px;
}
.historyGroupField .detailInlineGroup {
  margin-bottom: 0;
}
.enumerationField {
  padding: 10px 0;
  position: relative;
}
.enumerationField__option {
  min-height: 36px;
}
.consentDialog {
  font-weight: 300;
}
.consentDialog__heading {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  padding: 16px 24px;
  margin: 0;
}
.consentDialog__actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.consentDialog__checkbox {
  margin: 0;
}
.consentDialog__continueButton {
  margin: 0;
}
.globalSearch__wrapper {
  height: 40px;
}
.globalSearch__wrapper.globalSearch__wrapper--center {
  width: 50%;
  max-width: 700px;
}
.globalSearch__wrapper.globalSearch__wrapper--center .MuiAutocomplete-listbox {
  /*
    the structure of welcome page is:
        64px   AppBar
        10px   content margin
        x      blank space above logo
        328px  content
               - 60px of 328px takes bottom margin of global search component
        x      blank space below global search
        10px   content margin

     maximum listBox height is a space under global search component:
        bottom margin of GS component + x - padding/margin of the dropdown
     */
  max-height: calc(60px + calc((100vh - 64px - 2 * 10px - 328px) / 2) - 34px);
}
.globalSearch__wrapper.globalSearch__wrapper--right {
  width: 250px;
}
@media (max-width: 1000px) {
  .globalSearch__wrapper.globalSearch__wrapper--right {
    max-width: 105px;
  }
}
.globalSearch__content {
  left: 100%;
  transition: left 300ms;
}
.globalSearch__content.globalSearch__contentRight--focused {
  position: absolute;
  left: 30%;
  z-index: 1004;
}
@media (max-width: 1000px) {
  .globalSearch__content.globalSearch__contentRight--focused {
    left: 10%;
  }
}
.globalSearch {
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}
.globalSearch.globalSearch--focused {
  border: 1px solid #ddd;
  z-index: 1004;
}
.globalSearch.globalSearch--focused .MuiAutocomplete-inputRoot,
.globalSearch.globalSearch--focused .MuiAutocomplete-inputRoot:hover {
  background-color: white;
}
.globalSearch.globalSearch--hasValue .MuiAutocomplete-clearIndicator {
  visibility: visible;
  margin-right: 4px;
}
.globalSearch .globalSearch__autocomplete {
  position: relative;
  z-index: 1004;
}
.globalSearch .MuiInput-underline:after,
.globalSearch .MuiInput-underline:before {
  display: none;
}
.globalSearch .MuiAutocomplete-listbox {
  max-height: 80vh;
}
.globalSearch .MuiAutocomplete-inputRoot {
  padding: 6px;
  background-color: #E7E9EC;
  transition: background-color 200ms;
}
.globalSearch .MuiAutocomplete-inputRoot:hover {
  background-color: #CED3D9;
}
.globalSearch .FullScreenOverlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.globalSearch .MuiAutocomplete-noOptions {
  text-align: left;
}
.globalSearchListItem {
  font-weight: 300;
  position: relative;
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.globalSearchListItem .globalSearchListItem__icon {
  position: absolute;
  left: 4px;
  top: 8px;
}
.globalSearchListItem .globalSearchListItem__name {
  padding-left: 50px;
  font-size: 18px;
  height: 26px;
}
.globalSearchListItem .globalSearchListItem__description {
  padding-left: 50px;
  height: 18px;
  font-size: 12px;
  color: #8691A0;
}
.globalSearchListItem .accurity-tooltip {
  text-align: left;
}
.globalSearchListItem .accurity-tooltip > div {
  -webkit-line-clamp: 6;
}
.globalSearchHighlighter {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.globalSearchHighlighter mark {
  background-color: transparent;
  font-weight: bold;
}
.globalSearchHighlighter .globalSearchHighlighter__hiddenText {
  position: absolute;
  top: -100%;
}
.nodeGroup {
  width: 100%;
  max-width: 280px;
  padding: 7px;
  border: 2px dashed #c6c6c6;
  border-radius: 5px;
  cursor: pointer;
}
.nodeGroup > .nodeGroup__children > *:not(:last-child) {
  margin-bottom: 10px;
}
.node {
  position: relative;
  max-width: 280px;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 5px 1px #acacac;
  border: 2px solid transparent;
  cursor: pointer;
}
.node.node--highlighted {
  border: 2px solid #6D8CE2;
}
.Quality .node.node--highlighted {
  border: 2px solid #81bb26;
}
.Reference .node.node--highlighted {
  border: 2px solid #ffb500;
}
.Accurity .node.node--highlighted {
  border: 2px solid #6D8CE2;
}
.node.node--unlinked {
  background-color: #ebebeb;
}
.node.node--mini {
  max-width: 90px;
}
.node > .node__children {
  padding: 0 4px;
}
.node > .node__children > * {
  margin-bottom: 4px;
}
.node .node {
  box-shadow: none;
  border: 2px solid #dedede;
}
.node--dimmed > .nodeContent {
  opacity: 0.4;
}
.node__badge {
  position: absolute;
  left: -1px;
  bottom: -1px;
}
.nodeContent {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 8px;
  box-sizing: border-box;
}
.nodeContent__icon {
  margin-right: 10px;
  flex-shrink: 0;
}
.nodeContent__label {
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  overflow: hidden;
}
.nodeExpandButton {
  position: absolute;
  top: 4px;
  right: -20px;
}
.nodeExpandButton .nodeExpandButton__background {
  position: absolute;
  z-index: 1;
  transition: opacity 0.1s;
  left: 12px;
  top: 12px;
  right: 12px;
  bottom: 12px;
  background-color: white;
  border-radius: 50%;
}
.nodeExpandButton .nodeExpandButton__icon {
  z-index: 2;
  transition: opacity 0.3s;
}
.node > .nodeExpandButton--expanded .nodeExpandButton__background,
.node > .nodeExpandButton--expanded .nodeExpandButton__icon {
  opacity: 0;
}
.node:hover > .nodeExpandButton--expanded .nodeExpandButton__background,
.node:hover > .nodeExpandButton--expanded .nodeExpandButton__icon {
  opacity: 1;
}
.linksContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.link__path {
  fill: none;
  stroke-width: 2px;
  stroke: #c6c6c6;
}
.link__path--highlighted {
  stroke: #6D8CE2;
}
.Quality .link__path--highlighted {
  stroke: #81bb26;
}
.Reference .link__path--highlighted {
  stroke: #ffb500;
}
.Accurity .link__path--highlighted {
  stroke: #6D8CE2;
}
.link__label {
  fill: #6D8CE2;
}
.Quality .link__label {
  fill: #81bb26;
}
.Reference .link__label {
  fill: #ffb500;
}
.Accurity .link__label {
  fill: #6D8CE2;
}
.linkToggle__listLabel {
  padding-right: 40px;
}
.exportToPdfOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #E7E9EC;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exportToPdfOverlay.fade-in {
  z-index: 3;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}
.exportToPdfOverlay.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
}
.diagram__container {
  height: 100%;
  width: 100%;
  overflow-x: scroll;
}
.diagram__topBar {
  position: sticky;
  left: 0;
  height: 40px;
  width: 100%;
}
.diagram__content {
  position: relative;
  height: 100%;
  min-width: 1000px;
  display: inline-flex;
  justify-content: left;
}
.diagram_topButtons {
  z-index: 9;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.diagram__column {
  max-height: 100%;
  min-width: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.diagram__column.diagram__column--mini {
  min-width: 120px;
}
.diagram__column::-webkit-scrollbar {
  display: none;
}
.diagram__column > * {
  margin-bottom: 20px;
}
.diagram__columnVerticalCenterer {
  width: 100%;
  height: 50px;
  margin: auto 0;
}
.revisionColoring {
  position: relative;
}
.revisionColoring::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -16px;
  right: -16px;
}
.revisionColoring::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -16px;
  width: 5px;
}
.revisionColoring .MuiInputBase-root.Mui-disabled {
  color: #061220;
}
.revisionColoring .MuiInput-underline:before {
  border-bottom: 1px solid #B6BDC6;
}
.revisionColoring--red::before {
  background-color: #ff7d7d33;
}
.revisionColoring--red::after {
  background-color: #ed1c24;
}
.revisionColoring--green::before {
  background-color: #26ff471f;
}
.revisionColoring--green::after {
  background-color: #22b14c;
}
.versionBrowserRevisionSelector {
  width: 100%;
  display: flex;
  column-gap: 30px;
}
.versionBrowserRevisionSelector .MuiAutocomplete-root {
  flex: 1;
}
.versionBrowserRevisionSelector .MuiAutocomplete-inputRoot input {
  cursor: pointer;
}
.versionBrowserModal .versionBrowserModal__main {
  height: calc(100% - 120px);
  max-width: 1000px;
  margin: 60px auto;
  outline: none;
}
.versionBrowserModal .MuiPaper-root {
  position: relative;
  padding: 20px;
  max-height: calc(100% - 106px);
  box-sizing: border-box;
  overflow-y: auto;
}
.versionBrowserModal .MuiPaper-root:first-child {
  margin-bottom: 8px;
  padding-top: 30px;
}
.versionBrowserModal .closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
}
/*
 Field specific size of highlighting
 */
.detailInlineGroup.revisionColoring::before {
  left: 0;
  margin-top: 16px;
}
.detailInlineGroup.revisionColoring::after {
  left: 0px;
  margin-top: 16px;
}
.detailInlineGroup .detailInlineGroup__item .revisionColoring::before {
  left: -8px;
  right: -8px;
}
.detailInlineGroup .detailInlineGroup__item .revisionColoring::after {
  left: -10px;
}
.detailInlineGroup .detailInlineGroup__item:first-child .revisionColoring::before,
.detailInlineGroup .detailInlineGroup__item:first-child .revisionColoring::after {
  left: -16px;
}
.detailInlineGroup .detailInlineGroup__item:last-child .revisionColoring::before {
  right: -16px;
}
.detailInlineGroup.detailInlineGroup--indentLeft .detailInlineGroup__item .revisionColoring::before,
.detailInlineGroup.detailInlineGroup--indentLeft .detailInlineGroup__item .revisionColoring::after {
  left: -72px;
}
.detailInlineGroup.detailInlineGroup--indentRight .detailInlineGroup__item .revisionColoring::before {
  right: -60px;
}
.collectionField__row .revisionColoring.detailInlineGroup::before {
  left: -10px;
  right: -26px;
}
.collectionField__row .revisionColoring.detailInlineGroup::after {
  left: -10px;
}
.collectionField__row .revisionColoring:not(.detailInlineGroup)::before {
  left: -26px;
  right: -26px;
}
.collectionField__row .revisionColoring:not(.detailInlineGroup)::after {
  left: -26px;
}
.comment {
  position: relative;
  font-weight: 300;
}
.comment .MuiInputBase-root.Mui-disabled {
  color: #061220;
}
.comment .commentAuthorInfo {
  display: flex;
  justify-content: space-between;
  color: #B6BDC6;
  font-size: 12px;
  margin-bottom: 6px;
}
.comment .commentText {
  padding-top: 6px;
  padding-bottom: 6px;
}
.comment .commentText.commentText--deleteEditButtonsVisible {
  background-color: rgba(153, 153, 153, 0.2);
  color: #8691A0;
}
.comment .commentText.commentText--removed {
  font-style: italic;
  color: #B6BDC6;
}
.comment .commentText.commentText--removed .MuiOutlinedInput-notchedOutline {
  border-style: dotted;
}
.comment .commentText.commentText--editing textarea {
  margin-bottom: 1.4rem;
}
.comment .commentActionButtons {
  display: flex;
  white-space: nowrap;
  position: absolute;
  right: 4px;
  bottom: 2px;
  padding-left: 4px;
  background-color: white;
}
.comment .commentActionButtons.commentActionButtons--editDelete {
  background-color: #ebebeb;
}
.comment .commentRemovalMessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  background: white;
}
.comment .commentRemovalMessage .commentRemovalMessage__text {
  position: absolute;
  left: 14px;
  bottom: 6px;
}
.comment .commentEditInfo {
  font-size: 12px;
  color: #B6BDC6;
  margin-top: -6px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: right;
  position: relative;
}
.comment .commentEditInfo span {
  background: white;
  display: inline;
  box-shadow: 5px 0 0 white, -5px 0 0 white;
}
.commentInputField {
  font-weight: 300;
  color: #061220;
  line-height: 1.4rem;
  margin-bottom: -3px;
  display: flex;
}
.commentInputField .commentInputField__sendButton {
  position: absolute;
  bottom: 7px;
  right: 4px;
  color: #B6BDC6;
}
.commentInputField--hasValue .commentInputField__sendButton {
  color: #6D8CE2;
}
.Quality .commentInputField--hasValue .commentInputField__sendButton {
  color: #81bb26;
}
.Reference .commentInputField--hasValue .commentInputField__sendButton {
  color: #ffb500;
}
.Accurity .commentInputField--hasValue .commentInputField__sendButton {
  color: #6D8CE2;
}
.commentInputField.Mui-focused textarea,
.commentInputField.commentInputField--hasValue textarea {
  margin-bottom: 1.4rem;
}
.commentsContainer {
  font-weight: 300;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.commentsContainer .commentsContainer__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.1em;
  vertical-align: middle;
  color: #061220;
}
.commentsContainer .commentsContainer__title > * {
  vertical-align: middle;
}
.commentsContainer .commentsContainer__topBar {
  position: absolute;
  top: 10px;
  right: 12px;
}
.commentsContainer .commentsContainer__loadingIndicator {
  margin-bottom: -4px;
  margin-left: -16px;
  margin-right: -16px;
  min-height: 4px;
}
.commentsContainer .commentsList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin-bottom: 10px;
}
.commentsContainer .commentsList.commentsList--withScrollBar {
  padding: 10px 10px 10px 16px;
  border-color: silver;
  margin: 0 -16px 10px -16px;
}
.commentsContainer .comment {
  margin-bottom: 15px;
}
.commentsContainer .comment:last-child {
  margin-bottom: 0;
}
.commentsContainer .newCommentsMessage {
  color: #8691A0;
  cursor: pointer;
  display: inline-block;
  margin: -4px auto 6px auto;
}
.commentsContainer .newCommentsMessage:hover {
  text-decoration: underline;
}
.accurityMainPanel {
  flex-grow: 1;
  height: calc(100% - 20px);
  min-width: 60px;
  margin: 10px 10px 10px 0;
  box-sizing: border-box;
  position: relative;
}
.boxAndWhiskerChart {
  font-weight: 500;
  position: relative;
  height: 54px;
  margin-right: 5px;
  margin-left: 5px;
}
.boxAndWhiskerChart .boxAndWhiskerChart__backgroundLine {
  height: 2px;
  background-color: #E7E9EC;
  position: absolute;
  top: calc(50% - 1px);
  width: 100%;
}
.boxAndWhiskerChart .accurity-tooltip {
  text-align: center;
}
.boxAndWhiskerChart__deviation {
  background-color: #edf1fb;
  height: 5px;
  position: absolute;
  top: calc(50% - 2.5px);
  transition: all 0.15s;
  border-radius: 2px;
}
.Quality .boxAndWhiskerChart__deviation {
  background-color: #c7e991;
}
.Reference .boxAndWhiskerChart__deviation {
  background-color: #ffe199;
}
.Accurity .boxAndWhiskerChart__deviation {
  background-color: #edf1fb;
}
.boxAndWhiskerChart__deviation:hover {
  background-color: #c2cff3;
  height: 8px;
  top: calc(50% - 4px);
}
.Quality .boxAndWhiskerChart__deviation:hover {
  background-color: #b1e067;
}
.Reference .boxAndWhiskerChart__deviation:hover {
  background-color: #ffd366;
}
.Accurity .boxAndWhiskerChart__deviation:hover {
  background-color: #c2cff3;
}
.boxAndWhiskerChart__dotWrapper {
  position: absolute;
  left: 0;
  top: calc(50% - 10px / 2);
  transform: translateX(-50%);
}
.boxAndWhiskerChart__dotWrapper:hover .boxAndWhiskerChart__dotPoint {
  background-color: #587bde;
}
.Quality .boxAndWhiskerChart__dotWrapper:hover .boxAndWhiskerChart__dotPoint {
  background-color: #72a622;
}
.Reference .boxAndWhiskerChart__dotWrapper:hover .boxAndWhiskerChart__dotPoint {
  background-color: #e6a300;
}
.Accurity .boxAndWhiskerChart__dotWrapper:hover .boxAndWhiskerChart__dotPoint {
  background-color: #587bde;
}
.boxAndWhiskerChart__dotWrapper:hover .boxAndWhiskerChart__dotLabel {
  color: #061220;
}
.boxAndWhiskerChart__dotWrapper .accurity-tooltip {
  white-space: nowrap;
}
.boxAndWhiskerChart__dotWrapper .boxAndWhiskerChart__dotPoint {
  background-color: #6D8CE2;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: all 0.15s;
}
.Quality .boxAndWhiskerChart__dotWrapper .boxAndWhiskerChart__dotPoint {
  background-color: #81bb26;
}
.Reference .boxAndWhiskerChart__dotWrapper .boxAndWhiskerChart__dotPoint {
  background-color: #ffb500;
}
.Accurity .boxAndWhiskerChart__dotWrapper .boxAndWhiskerChart__dotPoint {
  background-color: #6D8CE2;
}
.boxAndWhiskerChart__dotLabel {
  font-size: 11px;
  color: #8691A0;
  position: absolute;
  bottom: -22px;
  cursor: default;
}
.boxAndWhiskerChart__dotWrapper--alignLeft .boxAndWhiskerChart__dotLabel {
  left: 0;
}
.boxAndWhiskerChart__dotWrapper--alignRight .boxAndWhiskerChart__dotLabel {
  right: 0;
}
.boxAndWhiskerChart__dotWrapper--alignCenter .boxAndWhiskerChart__dotLabel {
  left: 0;
  transform: translateX(calc(-50% + 10px / 2));
}
.boxAndWhiskerChart__dotWrapper--alignTop .boxAndWhiskerChart__dotLabel {
  bottom: unset;
  top: -22px;
}
.columnProfilingCanvas {
  height: calc(100% + 10px);
  overflow: hidden;
  margin-right: -10px;
}
.columnProfilingCanvas .columnProfilingCanvas__header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.columnProfilingCanvas .columnProfilingCanvas__headerLeftNodes {
  display: flex;
  height: 60px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.columnProfilingCanvas .columnProfilingCanvas__headerRightNodes {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.columnProfilingCanvas .columnProfilingCanvas__icon {
  width: 48px;
  height: 48px;
  position: absolute;
}
.columnProfilingCanvas .columnProfilingCanvas__title {
  font-size: 20px;
  color: #061220;
  white-space: nowrap;
  margin-left: 60px;
}
.columnProfilingCanvas .columnProfilingCanvas__subtitle {
  margin-left: 60px;
  color: #8691A0;
  font-size: 14px;
}
.columnProfilingCanvas .columnProfilingCanvas__contentContainer {
  height: calc(100% - 60px);
  overflow: auto;
  padding: 0 20px;
  margin-right: 0;
}
.columnProfilingCanvas .columnProfilingCanvas__content {
  column-count: 1;
  padding-bottom: 10px;
}
.columnProfilingCanvas .columnProfilingCanvas__content.columnProfilingCanvas__content--twoColumns {
  column-count: 2;
  column-gap: 10px;
}
.columnProfilingCanvas .columnProfilingCanvas__contentItem {
  padding-bottom: 10px;
  break-inside: avoid-column;
}
.columnProfilingCanvas .columnProfilingCanvas__contentItem:last-child {
  padding-bottom: 0;
}
.frequencyTable .MuiTableContainer-root {
  max-height: 406px;
}
.frequencyTable .frequencyTable__sortIcon {
  opacity: 1;
  color: rgba(0, 0, 0, 0.6);
  vertical-align: middle;
  font-size: 18px;
  margin-right: 6px;
}
.frequencyTable .frequencyTable__thermometerCell {
  width: 150px;
}
.frequencyTable .frequencyTable__row td,
.frequencyTable .frequencyTable__row th {
  padding-left: 0;
  padding-right: 25px;
}
.frequencyTable .frequencyTable__row td:last-child,
.frequencyTable .frequencyTable__row th:last-child {
  padding-right: 0;
}
.frequencyTable .MuiTableCell-body {
  border: none;
}
.histogram {
  font-weight: 500;
  position: relative;
  margin-top: 10px;
}
.histogram__container {
  height: 200px;
  padding-left: 40px;
  position: relative;
}
.histogram__barsWrapper {
  height: 100%;
  min-height: 100%;
  display: flex;
}
.histogramYAxis {
  font-size: 12px;
  color: #8691A0;
  position: absolute;
  top: -7px;
  bottom: -4px;
  left: 0;
  width: 25px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.histogramXAxis {
  font-size: 12px;
  color: #8691A0;
  margin-top: 10px;
  margin-left: 40px;
  display: flex;
  justify-content: space-around;
}
.histogramXAxis--angledTicks .histogramXAxis__label {
  writing-mode: vertical-rl;
}
.histogramXAxis__label {
  text-align: center;
}
.histogramXAxis__labelWrapper--hover .histogramXAxis__label {
  color: #061220;
  font-weight: bold;
}
.histogramXAxis--ticksBetweenBars {
  justify-content: space-between;
}
.histogramXAxis--ticksBetweenBars .histogramXAxis__labelWrapper {
  position: relative;
  min-height: 14px;
  width: 0 !important;
  display: flex;
  justify-content: center;
}
.histogramBar {
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
}
.histogramBar__rectangle {
  background-color: #98aeea;
  width: 100%;
  max-width: 80px;
  position: relative;
  border-radius: 5px;
}
.Quality .histogramBar__rectangle {
  background-color: #9bd73d;
}
.Reference .histogramBar__rectangle {
  background-color: #ffc433;
}
.Accurity .histogramBar__rectangle {
  background-color: #98aeea;
}
.histogramBar__labelWrapper {
  display: none;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  font-size: 12px;
  background-color: white;
  padding: 4px;
  border-radius: 2px;
  white-space: nowrap;
  color: #061220;
}
.histogramBar__value {
  font-weight: bold;
}
.histogramBar__percentage:before {
  content: "(";
}
.histogramBar__percentage:after {
  content: ")";
}
.histogramBar:hover .histogramBar__rectangle {
  background-color: #6d8ce2;
  position: relative;
  cursor: pointer;
}
.Quality .histogramBar:hover .histogramBar__rectangle {
  background-color: #81bb26;
}
.Reference .histogramBar:hover .histogramBar__rectangle {
  background-color: #ffb500;
}
.Accurity .histogramBar:hover .histogramBar__rectangle {
  background-color: #6d8ce2;
}
.histogramBar:hover .histogramBar__labelWrapper {
  display: block;
}
.profileCard {
  min-width: 450px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: unset;
}
.profileCard__title {
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 10px;
}
.thermometer__totalLabel {
  width: 100%;
  text-align: right;
  font-weight: 500;
  font-size: 24px;
  margin-top: -20px;
  margin-bottom: 10px;
}
.thermometer__container {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.thermometer__background {
  background-color: #E7E9EC;
  height: 100%;
  width: 100%;
  position: absolute;
}
.thermometer__segment {
  height: 100%;
  float: left;
  position: relative;
  background-color: #6D8CE2;
}
.Quality .thermometer__segment {
  background-color: #81bb26;
}
.Reference .thermometer__segment {
  background-color: #ffb500;
}
.Accurity .thermometer__segment {
  background-color: #6D8CE2;
}
.thermometer__segment--secondary {
  background-color: #b1c2f0;
}
.Quality .thermometer__segment--secondary {
  background-color: #a8dd56;
}
.Reference .thermometer__segment--secondary {
  background-color: #ffcd52;
}
.Accurity .thermometer__segment--secondary {
  background-color: #b1c2f0;
}
.thermometerLegend__container {
  padding: 10px 12px 0;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.thermometerLegend__dataPoint {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #8691A0;
}
.thermometerLegend__dot {
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 5px;
  background-color: #E7E9EC;
  margin-right: 10px;
}
.thermometerLegend__dot--primary {
  background-color: #6D8CE2;
}
.Quality .thermometerLegend__dot--primary {
  background-color: #81bb26;
}
.Reference .thermometerLegend__dot--primary {
  background-color: #ffb500;
}
.Accurity .thermometerLegend__dot--primary {
  background-color: #6D8CE2;
}
.thermometerLegend__dot--secondary {
  background-color: #b1c2f0;
}
.Quality .thermometerLegend__dot--secondary {
  background-color: #a8dd56;
}
.Reference .thermometerLegend__dot--secondary {
  background-color: #ffcd52;
}
.Accurity .thermometerLegend__dot--secondary {
  background-color: #b1c2f0;
}
.profileCard .thermometer__totalLabel:first-child {
  margin-top: -34px;
}
.thermometerLegend__label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 6px;
}
.columnProfiling--exportToPdf {
  width: 1400px;
  padding-top: 20px;
}
.columnProfiling--exportToPdf .columnProfilingCanvas__headerRightNodes {
  display: none;
}
.columnProfiling--exportToPdf .profileCard {
  border: 1px solid #d8d8d8;
}
.columnProfiling--exportToPdf .columnProfilingCanvas__icon {
  width: 68px;
  height: 68px;
  top: 10px;
  left: 10px;
}
.columnProfiling--exportToPdf .histogramBar__labelWrapper {
  display: block;
}
.columnProfiling--exportToPdf .histogramBar__value,
.columnProfiling--exportToPdf .histogramBar__percentage:before,
.columnProfiling--exportToPdf .histogramBar__percentage:after {
  display: none;
}
.dateField {
  display: flex;
  padding: 10px 0;
  position: relative;
}
.dateField__openButton {
  width: 56px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.cardListItem {
  width: 440px;
  height: 100%;
  box-sizing: border-box;
}
.cardListItem .MuiPaper-root {
  padding: 12px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.cardListItem .cardListItem__header {
  display: flex;
  align-items: center;
}
.cardListItem .cardListItem__headerTitle {
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardListItem .cardListItem__headerTitle:hover {
  cursor: pointer;
}
.cardListItem .cardListItem__headerTitle:hover::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  background-color: #6D8CE2;
}
.Quality .cardListItem .cardListItem__headerTitle:hover::before {
  background-color: #81bb26;
}
.Reference .cardListItem .cardListItem__headerTitle:hover::before {
  background-color: #ffb500;
}
.Accurity .cardListItem .cardListItem__headerTitle:hover::before {
  background-color: #6D8CE2;
}
.cardListItem .cardListItem__synonym {
  font-size: 14px;
  margin-left: 46px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
}
.cardListItem .cardListItem__description {
  flex-grow: 1;
  margin-top: 16px;
}
.cardListItem .cardListItem__description .richTextEditorWrapper {
  padding-bottom: 0;
}
.cardListItem .cardListItem__description .RichEditor-root {
  background: #F3F4F5;
}
.cardListItem .cardListItem__chips {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 4px;
  margin-top: 16px;
}
.cardList {
  position: relative;
  height: 100%;
}
.cardList .cardList__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.cardList .cardList__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 440px);
  grid-gap: 10px;
  justify-content: center;
  height: unset !important;
  overflow: unset !important;
  padding-bottom: 72px;
  position: relative;
}
.cardList .cardList__loader {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}
.circularProgress {
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
}
.circularProgress .circularProgress__label {
  position: absolute;
  top: 2px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.circularProgress .MuiCircularProgress-static {
  transform: rotate(-90deg) rotateX(180deg) !important;
}
.circularProgress .MuiCircularProgress-circle {
  stroke-width: 4;
}
.interactiveDiagram__container {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 900px;
  background: #fff;
}
.interactiveDiagram__controlsBar {
  width: 100%;
  height: 64px;
  padding: 0 4px;
  box-sizing: border-box;
  border-bottom: 1px solid #E7E9EC;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.interactiveDiagram__controlsBar > * {
  margin: 0 12px;
}
.interactiveDiagram__controlsBar .flatButton {
  font-weight: 500;
}
.interactiveDiagram__controlsBar .flatButton:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.interactiveDiagram__controlsBar p {
  margin: 0;
  width: 40px;
  text-align: center;
  pointer-events: none;
}
.interactiveDiagram__canvasContainer {
  width: 100%;
  height: 100%;
}
.interactiveCanvas__container {
  position: relative;
  width: 100%;
  height: calc(100% - 128px);
}
.interactiveCanvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.interactiveNode__shape {
  stroke-width: 2;
}
.interactiveNode__label {
  font-weight: 300;
  font-size: 14px;
  text-anchor: middle;
  alignment-baseline: central;
  cursor: default;
}
.interactiveNode__menuButton circle {
  display: none;
}
.interactiveNode__menuButton rect {
  fill: none;
  pointer-events: all;
  cursor: pointer;
}
.interactiveNode:hover .interactiveNode__menuButton circle {
  display: block;
}
.interactiveNode__popover {
  padding: 6px;
}
.interactiveLink line,
.interactiveLink path {
  stroke-width: 2;
  stroke: #CED3D9;
}
.interactiveLink.interactiveLink--dashed line,
.interactiveLink.interactiveLink--dashed path {
  stroke-dasharray: 5;
}
.interactiveLink.interactiveLink--clickable:hover {
  cursor: pointer;
}
.interactiveLink.interactiveLink--clickable:hover line,
.interactiveLink.interactiveLink--clickable:hover path {
  stroke-width: 4;
}
.autocompleteSelector {
  width: 330px;
  height: 55px;
  position: relative;
}
.autocompleteSelector .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
  transform: translate(0, 26px) scale(1);
}
.autocompleteSelector .MuiAutocomplete-inputRoot {
  min-height: 39px;
  flex-wrap: nowrap;
}
.autocompleteSelector .Mui-focused .chipsCollection {
  order: 2;
  min-width: 25px;
  width: 25px;
}
.autocompleteSelector .Mui-focused .chipsCollection .accurity-tooltip {
  z-index: 1301;
}
.autocompleteSelector__options {
  position: static;
}
.autocompleteSelector__options.autocompleteSelector--allowSelectAll {
  margin-bottom: 42px;
}
.autocompleteSelector__options .autocompleteSelector__xMoreResults {
  height: 50px;
  margin-left: 46px;
}
.autocompleteSelector__options .autocompleteSelector__selectAll {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid silver;
  background-color: white;
  padding: 5px 10px;
  box-sizing: border-box;
  text-align: center;
}
.chipsCollection {
  display: block;
  min-width: 100%;
}
.chipsCollection .chipsCollection__container {
  display: flex;
}
.chipsCollection .chipsCollection__visibleTags {
  overflow: hidden;
  display: flex;
}
.chipsCollection .chipsCollection__allChips {
  display: flex;
  position: absolute;
  visibility: hidden;
}
.chipsCollection .chipsCollection__hiddenChipsCount {
  cursor: default;
  display: flex;
  align-items: center;
  margin-left: 2px;
}
.chipsCollection .chipsCollection__tooltip {
  font-size: 20px !important;
  pointer-events: auto !important;
  background-color: #fff;
  opacity: 1 !important;
  padding: 10px 5px 5px 10px;
  white-space: normal;
}
.chipsCollection .chipsCollection__tooltip:hover {
  visibility: visible !important;
}
.chipsCollection .chipsCollection__tooltip .MuiChip-root {
  margin-bottom: 5px;
  display: inline-flex;
}
.accurityAlert {
  position: fixed;
  min-width: 90%;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #363d4a;
  color: #FDFDFD;
  text-align: center;
}
.accurityAlert a,
.accurityAlert a:visited {
  color: #FDFDFD;
}
.accurityAlert a:hover {
  color: #f44336;
}
.dataCatalogSync__innerTable {
  background-color: #F3F4F5;
}
.dataCatalogSync__innerRow:last-child td {
  border-bottom: none;
}
.dataCatalogSyncDialog .MuiDialog-paper {
  max-width: 1600px;
}
.dataCatalogSyncDialog__narrow .MuiDialog-paper {
  max-width: 600px;
}
.dataCatalogSyncSelectionDialog .MuiDialog-paper {
  height: 100%;
}
.confirmCancelDialog .MuiDialog-paper {
  max-width: 450px;
  min-width: 450px;
  width: 450px;
}
.confirmCancelDialog .MuiDialog-paper p {
  white-space: pre-wrap;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}
.accurityListOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: #E7E9EC;
  box-shadow: 0 0 0 5px #E7E9EC;
}
.applicationContent__container {
  display: flex;
  height: calc(100vh - 64px);
  overflow-x: auto;
}
.mainMenu__container {
  flex-shrink: 0;
  max-height: 100%;
  margin: 20px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -400px;
  padding-right: 400px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mainMenu__container::-webkit-scrollbar {
  display: none;
}
.emailNotificationsToggle {
  width: 24px;
  height: 24px;
  margin-top: 12px;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 9;
  right: 56px;
}
.emailNotificationsToggle button {
  padding: 0;
}
.emailNotificationsToggle .icon .cls-1 {
  fill: rgba(0, 0, 0, 0.54);
}
.accurityDetail__container .detailTopBar {
  position: absolute;
  display: flex;
  align-items: center;
  top: 10px;
  right: 10px;
  z-index: 9;
}
.commentsDrawerWrapper {
  z-index: 10;
  width: 0;
  height: 100%;
}
.commentsDrawer {
  width: 440px;
  height: calc(100% - 40px);
  margin-top: 20px;
  margin-left: -9px;
}
.loadingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  background-color: #E7E9EC;
}
.loadingPage__logo {
  width: 300px;
}
.loadingPage__progressIndicator {
  width: 300px;
  margin-top: 20px;
}
.loadingPage__progressIndicator .MuiLinearProgress-bar {
  background-color: #e35205;
}
.loadingPage__message {
  margin-top: 20px;
}
.loadingPage__goBackLink {
  margin-top: 20px;
}
.productTermsScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
}
.productTermsScreen__logo {
  width: 300px;
}
.productTermsScreen .switchField {
  margin: 10px 0;
}
.productTermsScreen__buttonsContainer {
  display: flex;
  justify-content: space-between;
}
.versionBrowserField__versionBrowserButton {
  margin: 16px 0;
}
.versionBrowserComparison {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 36px;
}
.versionBrowserComparison .versionBrowserField-old {
  grid-column: 1;
}
.versionBrowserComparison .versionBrowserField-new {
  grid-column: 2;
}
.versionBrowserComparison .accurityDetail__headerIcon {
  display: none;
}
.versionBrowserComparison .SingleLineField label {
  cursor: default !important;
}
.userPopup__button {
  margin: 8px 0;
}
.appSettings__button {
  margin: 8px 0;
}
.customLogoSettings__remove {
  margin: 8px 0;
}
.technicalDataMapping__joinInlineGroup.revisionColoring::before {
  left: -10px;
  right: -26px;
}
.technicalDataMapping__joinInlineGroup.revisionColoring::after {
  left: -10px;
}
.notFoundPage {
  height: 100vh;
  width: 100vw;
  background: url("../../images/background-dots.svg") no-repeat center center;
  background-size: 100% auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.notFoundPage__paper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 40px #00000014;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 48px;
}
.notFoundPage__logo {
  margin: 0 auto;
  display: block;
  height: 48px;
}
.notFoundPage__404 {
  text-align: center;
  font-size: 180px;
  font-weight: 500;
}
.notFoundPage__goHomeButton {
  text-align: center;
}
.notFoundPage__goHomeButton a {
  color: inherit;
}
.notFoundPage__text {
  font-size: 52px;
  line-height: 52px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: justify;
}
.notFoundPage__text:after {
  content: "";
  display: inline-block;
  width: 100%;
}
