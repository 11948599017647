@import '../../../../node_modules/ts-components/src/styles/variables.less';

.notFoundPage {
  height: 100vh;
  width: 100vw;
  background: url("../../../../../images/background-dots.svg") no-repeat center center;
  background-size: 100% auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.notFoundPage__paper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 40px #00000014;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 48px;
}

.notFoundPage__logo {
  margin: 0 auto;
  display: block;
  height: 48px;
}

.notFoundPage__404 {
  text-align: center;
  font-size: 180px;
  font-weight: 500;
}

.notFoundPage__goHomeButton {
  text-align: center;
  a {
    color: inherit;
  }
}

.notFoundPage__text {
  font-size: 52px;
  line-height: 52px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: justify;
}

.notFoundPage__text:after {
  content: "";
  display: inline-block;
  width: 100%;
}