.applicationContent__container {
  display: flex;
  height: calc(100vh - 64px);
  overflow-x: auto;
}

.mainMenu__container {
  flex-shrink: 0;
  max-height: 100%;
  margin: 20px 0;

  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -400px; // negative margin hack: https://stackoverflow.com/questions/28543627/overflow-y-hidden-breaks-overflow-x-visible
  padding-right: 400px;

  // Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
